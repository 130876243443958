import { useCallback, useEffect, useState } from 'react'
import { getReferralLanding, ReferralLanding } from './Sanity'

const useReferralLanding = (type: string) => {
  const [referralLanding, setReferralLanding] = useState<ReferralLanding>()
  const getReferralLandingData = useCallback(async () => {
    const data = (await getReferralLanding(type)) as ReferralLanding
    setReferralLanding(data)
  }, [type])

  useEffect(() => {
    getReferralLandingData()
  }, [])

  return {
    referralLanding,
  }
}

export default useReferralLanding
