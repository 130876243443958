import { ThemeProvider } from '@emotion/react'
import React, { FC, useCallback, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { recordReferralSignupStart } from '../lib/Analytics'
import { SubscribeState } from '../lib/SubscribeUtils'
import { colors, fonts, mq, spaces, styled, typography } from '../styles'
import Button from './Button'
import { SignupFormTheme, themes } from './EmailSignupForm'

const useEmail = (code?: string | null) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [signupToNewsletter, setSignupToNewsletter] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [subscribeState, setSubscribeState] = useState<SubscribeState>(
    SubscribeState.Initial,
  )

  const emailInputRef = useRef(null)

  const handleInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target
      const value = target.value
      if (target.name === 'name') setName(value)
      else if (target.name === 'newsletter')
        setSignupToNewsletter((prev) => !prev)
      else setEmail(value)
    },
    [],
  )

  const handleSubmit = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      if (email === '') {
        setErrorMessage('Please fill in your email.')
        setSubscribeState(SubscribeState.Failed)
      } else {
        setSubscribeState(SubscribeState.Started)

        recordReferralSignupStart(email, code ? code : undefined)

        navigate('/redeem-signup/', {
          state: {
            email: email,
            code: code,
            newsletter: signupToNewsletter,
          },
        })
      }
    },
    [email, code, signupToNewsletter],
  )

  return {
    name,
    email,
    signupToNewsletter,
    emailInputRef,
    handleInputChanged,
    handleSubmit,
    errorMessage,
    state: subscribeState,
  }
}

const ReferralSignupForm: FC<{
  theme: 'light' | 'dark'
  withName?: boolean
  code?: string | null
}> = ({ theme = 'light', withName, code }) => {
  const signup = useEmail(code)

  return (
    <ThemeProvider theme={themes[theme]}>
      <Form>
        <TextInputs>
          {withName && (
            <Input
              name="name"
              placeholder="From Name"
              value={signup.name}
              onChange={signup.handleInputChanged}
            />
          )}
          <Input
            name="email"
            type="email"
            placeholder="Email"
            value={signup.email}
            onChange={signup.handleInputChanged}
          />
          <SignupCheckbox>
            <input
              type="checkbox"
              id="newsletter"
              name="newsletter"
              checked={signup.signupToNewsletter}
              onChange={signup.handleInputChanged}
            />
            <SignupCheckboxLabel htmlFor="newsletter">
              I would like to receive the Oda newsletter
            </SignupCheckboxLabel>
          </SignupCheckbox>

          {signup.state == SubscribeState.Failed && (
            <ErrorMessage>{signup.errorMessage}</ErrorMessage>
          )}
        </TextInputs>

        <SubmitButton
          size={'medium'}
          colorScheme={theme === 'dark' ? 'light' : 'dark'}
          disabled={signup.state === SubscribeState.Started}
          onClick={signup.handleSubmit}>
          Sign up now
        </SubmitButton>
      </Form>
    </ThemeProvider>
  )
}

export const ReferralSignupBottomForm: FC<{
  theme: 'light' | 'dark'
  withName?: boolean
  code?: string | null
}> = ({ theme = 'light', withName, code }) => {
  const signup = useEmail(code)

  return (
    <ThemeProvider theme={themes[theme]}>
      <Form>
        <BottomTextInputs>
          <InputContainer>
            {withName && (
              <Input
                name="name"
                placeholder="From Name"
                value={signup.name}
                onChange={signup.handleInputChanged}
              />
            )}
            <Input
              name="email"
              type="email"
              placeholder="Email"
              value={signup.email}
              onChange={signup.handleInputChanged}
            />
          </InputContainer>
          <SignupCheckbox>
            <input
              type="checkbox"
              id="newsletter"
              name="newsletter"
              checked={signup.signupToNewsletter}
              onChange={signup.handleInputChanged}
            />
            <SignupCheckboxLabel htmlFor="newsletter">
              I would like to receive the Oda newsletter
            </SignupCheckboxLabel>
          </SignupCheckbox>

          {signup.state == SubscribeState.Failed && (
            <ErrorMessage>{signup.errorMessage}</ErrorMessage>
          )}
        </BottomTextInputs>

        <SubmitButton
          size={'medium'}
          colorScheme={theme === 'dark' ? 'light' : 'dark'}
          disabled={signup.state === SubscribeState.Started}
          onClick={signup.handleSubmit}>
          Sign up now
        </SubmitButton>
      </Form>
    </ThemeProvider>
  )
}

export default ReferralSignupForm

const Form = styled.form(mq({}))

const TextInputs = styled.div(
  mq({
    margin: '0 auto',
    marginBottom: spaces.medium,
  }),
)

const BottomTextInputs = styled.div(
  mq({
    margin: '0 auto',
    marginBottom: spaces.small,
  }),
)

const InputContainer = styled.div(
  mq({
    display: 'flex',
    flexDirection: ['row', 'column'],
  }),
)

const Input = styled.input<{ addPaddingForNext?: boolean }>(
  ({ theme: t, addPaddingForNext }) => {
    const theme = t as SignupFormTheme
    return mq({
      display: 'block',
      width: [350, '100%'],
      maxWidth: '100%',
      border: theme.inputBorder,
      borderRadius: 15,
      padding: '15px 20px',
      margin: '0 auto',
      marginBottom: ['15px !important', 10],
      marginRight: addPaddingForNext ? [15, 0] : 'auto',
      backgroundColor: '#EDE6D9',
      fontFamily: fonts.monosten.light,
      color: colors.goldenBrown,
      fontSize: ['24px', '32px', '21px'],
      '::-webkit-input-placeholder': {
        color: theme.placeholderColor,
      },
      ':-webkit-autofill': {
        boxShadow: `0px 0px 0px 60px #EDE6D9 inset`,
      },
      ':-webkit-autofill::first-line': {
        fontSize: ['24px', '32px', '21px'],
      },
      ':last-child': {
        marginBottom: [0, 0, 0, 0],
      },
      ':focus': {
        borderRadius: 15,
        outline: 'none',
        boxShadow: `2px 2px 0px grey`,
      },
    })
  },
)

const SignupCheckbox = styled.div(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  typography.tinyTinySerif,
  mq({
    lineHeight: [1],
  }),
)

const SignupCheckboxLabel = styled.label(({ theme: t }) => {
  const theme = t as SignupFormTheme
  return (
    typography.tinyTinySerif,
    mq({ color: theme.foregroundColor, marginBottom: -4, marginLeft: 10 })
  )
})

const ErrorMessage = styled.div(
  typography.tinyMono,
  mq({
    textAlign: 'center',
  }),
)

const SuccessMessage = styled.div(
  typography.smallSans,
  mq({
    textAlign: 'center',
  }),
)

const SubmitButton = styled(Button)(
  mq({
    width: ['360px', '100%'],
    fontSize: ['38px', '26px'],
    padding: ['3px 24px 8px', '7px 0 9px'],
  }),
)
