import React, { FC, useCallback, Fragment } from 'react'
import { graphql, PageProps } from 'gatsby'
import { useQueryParam, StringParam } from 'use-query-params'
import { withPreview } from 'gatsby-source-prismic'
import { isMobile } from 'react-device-detect'

import Layout from '../components/Layout'
import '../components/base.css'
import { RedeemQuery } from '../../graphql-types'
import extractGlobals from '../lib/extractGlobals'
import ReferralSignupForm, {
  ReferralSignupBottomForm,
} from '../components/ReferralSignupForm'
import { colors, mq, spaces, styled, typography } from '../styles'
import useReferralLanding from '../lib/useReferralLanding'
import SanityFluidImage, {
  getSanitySrcSet,
} from '../components/SanityFluidImage'
import { LinkButton } from '../components/Button'

const Redeem: FC<PageProps<RedeemQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)
  const [type, setType] = useQueryParam('afmc', StringParam)

  const { referralLanding } = useReferralLanding(type ? type : 'default')

  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      {referralLanding ? (
        <>
          <Section>
            <HeroImageBlock>
              <BlockFullImage
                src={referralLanding.imageUrl}
                srcSet={getSanitySrcSet(referralLanding.imageUrl, 800)}
                sizes={'(max-width: 800px) 100%, 100%'}
              />
            </HeroImageBlock>
            <HeroBlock>
              <SignupBlock>
                {referralLanding.titleLogoImage && (
                  <LogoImage
                    srcSet={getSanitySrcSet(
                      referralLanding.titleLogoImageUrl,
                      300,
                    )}
                    src={referralLanding.titleLogoImageUrl}
                    // width={300}
                    sizes={'(max-width: 800px) 300px, 260px'}
                    alt={'Oda'}
                  />
                )}
                <Title>{referralLanding.title}</Title>
                <Subtitle>{referralLanding.body}</Subtitle>
                <div>
                  <ReferralSignupForm theme={'light'} code={type} />
                </div>
              </SignupBlock>
            </HeroBlock>
          </Section>
          {referralLanding?.infoBlock.map((info) => {
            const title = info.title.split('\\n')
            return (
              <Section key={info._key} backgroundColor={info.backgroundColor}>
                <Block>
                  <BlockTitle>
                    {isMobile
                      ? title.map((t, i) => (
                          <Fragment key={`title${i}`}>
                            <span>{t}</span>
                            {i !== title.length - 1 && ' '}
                          </Fragment>
                        ))
                      : title.map((t, i) => (
                          <Fragment key={`title${i}`}>
                            <span>{t}</span>
                            {i !== title.length - 1 && <br />}
                          </Fragment>
                        ))}
                  </BlockTitle>
                  {info.image && (
                    <DecoImage
                      imageAsset={info.image.asset}
                      url={info.imageUrl}
                      width={500}
                      sizes={'(max-width: 800px) 100vw, 100vw'}
                    />
                  )}
                  <Subtitle>{info.body}</Subtitle>
                  {info.learnMoreLink && (
                    <LearnMoreButton
                      colorScheme="light"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={info.learnMoreLink}>
                      LEARN MORE
                    </LearnMoreButton>
                  )}
                </Block>
              </Section>
            )
          })}
          <Section backgroundColor={colors.darkBrown}>
            <Block>
              <BottomSignupBodyText>
                {referralLanding.body}
              </BottomSignupBodyText>
              <ReferralSignupBottomForm theme={'dark'} withName={false} />
            </Block>
          </Section>
        </>
      ) : (
        <LoadingSpace />
      )}
    </Layout>
  )
}

export default withPreview(Redeem)

const Section = styled.div<{ backgroundColor?: string }>(
  {
    display: 'flex',
  },
  (props) => ({
    backgroundColor: props.backgroundColor
      ? props.backgroundColor
      : 'transparent',
  }),
  mq({
    flexDirection: ['row', 'column'],
  }),
)

const HeroImageBlock = styled.div(
  {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mq({
    height: ['calc(100vh - 60px)', 'auto', '50vh'],
  }),
)

const HeroBlock = styled.div(
  {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mq({
    height: ['calc(100vh - 60px)', 'auto', 'auto'],
  }),
)

const SignupBlock = styled.div(
  {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mq({
    paddingTop: spaces.large,
    paddingBottom: spaces.large,
  }),
)

const Block = styled.div(
  {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: 'solid 1px black',
  },
  mq({
    paddingTop: spaces.large,
    paddingBottom: spaces.large,
  }),
)

const Title = styled.h1(
  typography.mediumSerif,
  mq({
    textAlign: 'center',
    paddingBottom: spaces.small,
    fontSize: [55, 50],
    lineHeight: [1, 0.9],
    paddingLeft: [0, 30],
    paddingRight: [0, 30],
  }),
)

const BlockTitle = styled.h1(
  typography.extraLargeSerif,
  mq({
    textAlign: 'center',
    paddingBottom: spaces.small,
    maxWidth: 1000,
    paddingLeft: spaces.small,
    paddingRight: spaces.small,
  }),
)

const Subtitle = styled.div(
  {
    whiteSpace: 'pre-line',
  },
  typography.smallSerif,
  mq({
    textAlign: 'center',
    marginBottom: spaces.small,
    width: '85%',
    maxWidth: 780,
    fontSize: ['32px', '24px'],
  }),
)

const BottomSignupBodyText = styled.div(
  {
    whiteSpace: 'pre-line',
    color: colors.lightBrown,
    maxWidth: 1000,
  },
  typography.mediumSerif,
  mq({
    textAlign: 'center',
    marginBottom: spaces.small,
    width: '90%',
    fontSize: ['48px', '27px'],
  }),
)

const LogoImage = styled.img(
  {
    objectFit: 'contain',
  },
  mq({
    width: [300, 260],
  }),
)

const DecoImage = styled(SanityFluidImage)(
  {
    objectFit: 'contain',
  },
  mq({
    width: [500, '100%'],
    margin: '15px auto',
    marginBottom: spaces.medium,
    padding: [0, '0 20px'],
  }),
)

const BlockFullImage = styled.img(
  mq({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    padding: [0, '20px 20px 0 20px'],
  }),
)

const LoadingSpace = styled.div({
  minHeight: '100vh',
})

const LearnMoreButton = styled(LinkButton)(
  mq({
    backgroundColor: colors.lightBrown,
    color: colors.darkBrown,
    borderColor: colors.darkBrown,
    ':hover': {
      backgroundColor: colors.darkBrown,
      color: colors.lightBrown,
    },
  }),
)

export const pageQuery = graphql`
  query Redeem {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
  }
`
